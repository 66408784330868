import { nextTick } from '../utils/helpers';
import { Overlay, toggleOverlay } from '../utils/overlays';

const nix18PopupElement = document.querySelector<HTMLElement>(
  '[data-nix18="popup"]',
);
const nix18ConfirmElement = document.querySelector<HTMLButtonElement>(
  '[data-nix18="confirm"]',
);

const SESSION_STORAGE_KEY = 'nix18-popup';

(() => {
  if (
    !nix18PopupElement ||
    !nix18ConfirmElement ||
    sessionStorage.getItem(SESSION_STORAGE_KEY)
  ) {
    return;
  }

  toggleOverlay(Overlay.NIX18_POPUP, true);
  nix18PopupElement.classList.add('transition-none');
  nix18PopupElement.classList.remove('invisible', 'opacity-0');
  nextTick(() => {
    nix18PopupElement.classList.remove('transition-none');
  });

  nix18ConfirmElement.addEventListener('click', () => {
    toggleOverlay(Overlay.NIX18_POPUP, false);
    sessionStorage.setItem(SESSION_STORAGE_KEY, '1');
    nix18PopupElement.classList.add(
      'invisible',
      'opacity-0',
      'pointer-events-none',
    );
  });
})();
