import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { nextTick } from '../utils/helpers';
import { hasOpenOverlay, Overlay, toggleOverlay } from '../utils/overlays';

(() => {
  const navbarElement = document.querySelector<HTMLElement>('.navbar');
  if (!navbarElement) return;

  const navbarOpenerElement =
    navbarElement.querySelector<HTMLButtonElement>('.navbar__opener');
  const navbarCloserElement =
    navbarElement.querySelector<HTMLButtonElement>('.navbar__closer');
  if (!navbarOpenerElement || !navbarCloserElement) return;

  const toggleNavbar = (force?: boolean) => {
    toggleOverlay(
      Overlay.MENU,
      navbarElement.classList.toggle(
        'navbar--open',
        force ?? !hasOpenOverlay(Overlay.MENU),
      ),
    );
  };

  navbarOpenerElement.addEventListener('click', () => toggleNavbar(true));
  navbarCloserElement.addEventListener('click', () => toggleNavbar(false));

  BREAKPOINT_MEDIA_QUERIES.xl.addEventListener('change', () => {
    navbarElement.classList.add('navbar--no-transitions');

    nextTick(() => {
      navbarElement.classList.remove('navbar--no-transitions');
    });
  });
})();
