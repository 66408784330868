import { debounce } from 'lodash-es';
import { POSTCODE_API_URL } from '../constants';

const postcodeFields = document.querySelectorAll<HTMLDivElement>(
  '.woocommerce input[id$="postcode"]',
);

(() => {
  if (!import.meta.env.VITE_POSTCODE_API_TOKEN) {
    // eslint-disable-next-line no-console
    console.warn('No "VITE_POSTCODE_API_TOKEN" environment variable set.');
    return;
  }
  if (!postcodeFields.length) return;

  postcodeFields.forEach((postcodeField) => {
    const fieldWrapper = postcodeField.closest<HTMLDivElement>(
      '[class$="-fields__field-wrapper"]',
    );
    if (!fieldWrapper) return;

    const streetField = fieldWrapper.querySelector<HTMLDivElement>(
      '[id$="address_1_field"]',
    );
    const cityField =
      fieldWrapper.querySelector<HTMLDivElement>('[id$="city_field"]');

    const postcodeInput = fieldWrapper.querySelector<HTMLInputElement>(
      'input[id$="postcode"]',
    );
    const houseNumberInput = fieldWrapper.querySelector<HTMLInputElement>(
      'input[id$="house_number"]',
    );
    const houseNumberSuffixInput = fieldWrapper.querySelector<HTMLInputElement>(
      'input[id$="house_number_suffix"]',
    );
    const streetInput = streetField?.querySelector<HTMLInputElement>('input');
    const cityInput = cityField?.querySelector<HTMLInputElement>('input');
    const lookupWrapper = fieldWrapper.querySelector<HTMLDivElement>(
      '[data-address-lookup]',
    );

    if (
      !streetField ||
      !cityField ||
      !postcodeInput ||
      !houseNumberInput ||
      !houseNumberSuffixInput ||
      !streetInput ||
      !cityInput ||
      !lookupWrapper
    )
      return;

    const hideStreetCityInputs = (hide = true) => {
      [streetField, cityField].forEach((formField) => {
        formField.classList.toggle('hidden', hide);
        formField.style.removeProperty('display');
      });
    };

    const editAddressLink = fieldWrapper!.querySelector<HTMLAnchorElement>(
      '[data-edit-address]',
    );
    const hideLookupData = (hide = true) => {
      lookupWrapper.querySelector<HTMLSpanElement>(
        '[data-street]',
      )!.textContent = hide ? '' : streetInput.value;
      lookupWrapper.querySelector<HTMLSpanElement>(
        '[data-house-number]',
      )!.textContent = hide ? '' : houseNumberInput.value;
      lookupWrapper.querySelector<HTMLSpanElement>(
        '[data-house-number-suffix]',
      )!.textContent = hide ? '' : houseNumberSuffixInput.value;
      lookupWrapper.querySelector<HTMLSpanElement>(
        '[data-postcode]',
      )!.textContent = hide ? '' : postcodeInput.value;
      lookupWrapper.querySelector<HTMLSpanElement>('[data-city]')!.textContent =
        hide ? '' : cityInput.value;

      lookupWrapper
        .querySelector<HTMLSpanElement>('[data-edit-address]')!
        .classList.toggle('hidden', hide);
      lookupWrapper.classList.toggle('hidden', hide);
    };

    const addressDataListener = async () => {
      try {
        hideStreetCityInputs();

        const response = await fetch(
          `${POSTCODE_API_URL}/postcode?${new URLSearchParams({
            postcode: postcodeInput.value,
            number: houseNumberInput.value,
          })}`,
          {
            headers: {
              Authorization: `Bearer ${
                import.meta.env.VITE_POSTCODE_API_TOKEN
              }`,
            },
          },
        );

        const { street, city } = await response.json();
        if (!street || !city) throw new Error();

        streetInput.value = street;
        cityInput.value = city;

        hideStreetCityInputs(true);
        hideLookupData(false);
      } catch (error) {
        hideStreetCityInputs(false);
        // eslint-disable-next-line no-console
        console.warn(
          'An error occurred while trying to autocomplete address.',
          error,
        );
      }
    };

    [postcodeInput, houseNumberInput, houseNumberSuffixInput].forEach(
      (inputElement) =>
        inputElement.addEventListener(
          'input',
          debounce(addressDataListener, 300),
        ),
    );
    editAddressLink!.addEventListener('click', () => {
      hideLookupData(true);
      hideStreetCityInputs(false);
    });
  });
})();
