import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { BREAKPOINTS } from '../utils/breakpoints';

(() => {
  const postsBlockElements = document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--posts',
  );

  postsBlockElements.forEach((postsBlockElement) => {
    const swiperElement =
      postsBlockElement.querySelector<HTMLElement>('.swiper');
    if (!swiperElement) return;

    new Swiper(swiperElement, {
      modules: [Navigation],

      spaceBetween: 12,
      slidesPerView: 'auto',

      navigation: {
        prevEl: postsBlockElement.querySelector<HTMLButtonElement>(
          '.swiper-button-prev',
        ),
        nextEl: postsBlockElement.querySelector<HTMLButtonElement>(
          '.swiper-button-next',
        ),
      },

      breakpoints: {
        [BREAKPOINTS.lg]: {
          spaceBetween: 20,
        },
      },
    });
  });
})();
