import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { BREAKPOINTS } from '../utils/breakpoints';

const relatedProductsElement =
  document.querySelector<HTMLElement>('.related-products');

(() => {
  if (!relatedProductsElement) return;

  const swiperElement =
    relatedProductsElement.querySelector<HTMLElement>('.swiper');
  if (!swiperElement) return;

  new Swiper(swiperElement, {
    modules: [Navigation],

    slidesPerView: 1,
    spaceBetween: 50,

    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },

    on: {
      resize: (swiper) => {
        swiper.navigation.prevEl.parentElement?.classList.toggle(
          'hidden',
          swiper.navigation.prevEl.classList.contains('swiper-button-lock'),
        );
      },
    },

    breakpoints: {
      [BREAKPOINTS.sm]: {
        slidesPerView: 2,
      },
      [BREAKPOINTS.lg]: {
        slidesPerView: 3,
      },
    },
  });
})();
