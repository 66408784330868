import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { BREAKPOINTS } from '../utils/breakpoints';

(() => {
  const heroHeaderBlockElements = document.querySelectorAll<HTMLElement>(
    '.content-block.content-block--hero-header',
  );

  heroHeaderBlockElements.forEach((heroHeaderBlockElement) => {
    const swiperElement =
      heroHeaderBlockElement.querySelector<HTMLElement>('.swiper');
    if (!swiperElement) return;

    new Swiper(swiperElement, {
      modules: [Navigation],

      centeredSlides: true,
      spaceBetween: 32,
      slidesPerView: 'auto',

      navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      },

      breakpoints: {
        [BREAKPOINTS.lg]: {
          spaceBetween: 50,
        },
      },
    });
  });
})();
