import {
  DivIcon,
  LatLng,
  Map,
  Marker,
  Popup,
  TileLayer,
  FeatureGroup,
} from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import { createElement } from '../utils/helpers';
import markerSvg from '@/images/marker.svg';

Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

interface Location {
  name: string;
  lat: number;
  lng: number;
  address: string;
}

const LEAFLET_PROVIDER_TILES_MAP: Record<string, string> = {
  'Stadia.AlidadeSmoothDark':
    'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark',
};

const getTileTemplateUrl = (provider: string) =>
  LEAFLET_PROVIDER_TILES_MAP[provider] ?? 'https://{s}.tile.openstreetmap.org';

(() => {
  const sellingPointsMapElements = document.querySelectorAll<HTMLElement>(
    '.content-block--selling-points-map [data-selling-points-map]',
  );

  sellingPointsMapElements.forEach((sellingPointsMapElement) => {
    const data = sellingPointsMapElement.getAttribute(
      'data-selling-points-map',
    );
    if (!data) return;

    const locations = JSON.parse(data) as Location[];

    const map = new Map(sellingPointsMapElement, {
      center: new LatLng(52.25, 5.25),
      zoom: 7.5,
      zoomControl: false,
      attributionControl: false,
      // @ts-ignore
      gestureHandling: true,
    });

    const markers: Marker[] = [];

    new TileLayer(
      `${getTileTemplateUrl(
        import.meta.env.VITE_LEAFLET_PROVIDER,
      )}/{z}/{x}/{y}{r}.png`,
    ).addTo(map);

    locations.forEach(({ name, lat, lng, address }) => {
      const marker = new Marker([lat, lng], {
        icon: new DivIcon({
          html: markerSvg,
          iconSize: [23, 29],
          iconAnchor: [5, 12],
        }),
      });

      marker.bindPopup(
        // @ts-ignore
        new Popup({
          closeButton: false,
          offset: [7, 0],
          interactive: true,
          opacity: 1,
          content: createElement('div', { className: 'space-y-1' }, [
            createElement('h6', null, [name]),
            createElement('p', null, [address.replace(/Nederland$/, '')]),
            createElement(
              'a',
              {
                href: `https://www.google.com/maps/search/${address}`,
                target: '_blank',
              },
              ['Open in Google Maps'],
            ),
          ]),
        }),
      );

      marker.addEventListener('click', (e) => map.panTo(e.latlng));

      marker.addTo(map);

      markers.push(marker);
    });

    map.fitBounds(new FeatureGroup(markers).getBounds());
  });
})();
