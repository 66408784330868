jQuery(($) => {
  let timeout: NodeJS.Timeout;

  $('.woocommerce').on('change', 'input.qty', () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      $("[name='update_cart']").trigger('click');
    }, 250);
  });
});
