import { Overlay, toggleOverlay } from '../utils/overlays';

const addedToCartPopupElement = document.querySelector<HTMLElement>(
  '[data-added-to-cart-popup]',
);
if (addedToCartPopupElement) {
  toggleOverlay(Overlay.ADDED_TO_CART_POPUP, true);
}

// close woocommerce messages
document.addEventListener('click', (e) => {
  const { target } = e;
  if (
    !(target instanceof HTMLElement) ||
    !target.hasAttribute('data-hide-notice')
  ) {
    return;
  }

  e.preventDefault();

  const message = target.closest('.woocommerce-message');
  message?.classList.toggle('!hidden', true);

  if (addedToCartPopupElement) {
    toggleOverlay(Overlay.ADDED_TO_CART_POPUP, false);
  }
});
